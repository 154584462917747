import React from 'react';

import './ErrorPage.css';

function ErrorPage() {
  return (
    <div className="ErrorPage">
      <h3>Oh no! Page not found.</h3>
    </div>
  );
};

export default ErrorPage;
